import React, { FunctionComponent, useState } from 'react';

import moment from 'moment';

import { CalendarOutlined } from '@ant-design/icons/lib';

import { useTimezoneFormatter } from '../../hooks/timezone';
import { useInterval } from '../../hooks/interval';

import './styles.less';

export const DateTimeInfo: FunctionComponent = () => {
    const { profileFormatter } = useTimezoneFormatter();

    const [currentTime, setCurrentTime] = useState(moment());
    useInterval(() => {
        setCurrentTime(moment());
    }, 1000);

    return (
        <div className="DateTimeInfo">
            {/* Display current date & time */}
            <p className="DateTimeInfo__row">
                <CalendarOutlined className="DateTimeInfo__icon" />
                {profileFormatter.dateTime(currentTime)}
            </p>
        </div>
    );
};
