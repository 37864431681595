import React, { FunctionComponent } from 'react';

import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/ru_RU';

import moment from 'moment';
import 'moment/locale/ru';
import 'moment-timezone';

import { App } from '../App';

moment.locale('ru');

export const Root: FunctionComponent = () => {
    return (
        <ConfigProvider locale={locale}>
            <App />
        </ConfigProvider>
    );
};
