import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useInterval } from 'react-use';
import { Helmet } from 'react-helmet';

import clsx from 'clsx';
import { Badge, Pagination, Typography } from 'antd';

import { useStore } from 'effector-react';
import { DriverRoutesStore, fetchDriverRoutes } from '../../effector/driverRoutes';
import { $profileAbilityFilterRouteBranch } from '../../effector/profile';
import { FiltersPayloadStore } from '../../effector/filters';

import { useTimezoneFormatter } from '../../hooks/timezone';
import { showApiError } from '../../utils/notification';

import { Status } from '../Status';
import { Loader } from '../Loader';

import './styles.less';

const updateInterval = 5 * 60 * 1000;

type URLParams = { routeId?: string };
export const RouteList: FunctionComponent = () => {
    const { routeId } = useParams<URLParams>();

    const { routesFormatter } = useTimezoneFormatter();

    const loading = useStore(fetchDriverRoutes.pending);
    const filters = useStore(FiltersPayloadStore);
    const withBranches = useStore($profileAbilityFilterRouteBranch);
    const { items, page, total, page_size: size } = useStore(DriverRoutesStore);

    const request = useCallback(
        (currentPage = page) => {
            if (loading || (withBranches && !filters.branch_id)) return;
            fetchDriverRoutes({ page: currentPage, ...filters }).catch(showApiError);
        },
        [page, filters, loading, withBranches]
    );

    useEffect(request, [filters]);
    useInterval(request, updateInterval);

    return (
        <div className="RouteList__container">
            <Helmet>
                <title>Заявки | Триал</title>
            </Helmet>
            {loading && (
                <div className="RouteList__loader">
                    <Loader />
                </div>
            )}
            <div className={clsx('RouteList', withBranches && 'RouteList--withBranches')}>
                {items.map((item) => (
                    <Link
                        key={`route-${item.number}-${item.status}`}
                        to={`/routes/${item.id}`}
                        className={clsx(
                            'RouteList__item RouteList__itemLink',
                            item.number.toString() === routeId && 'RouteList__item--active'
                        )}
                    >
                        <div
                            className={clsx(
                                'RouteList__card',
                                item.status === 'finished' && 'RouteList__card--finished'
                            )}
                        >
                            <div className="RouteList__itemHeader">
                                <div className="RouteList__itemHeader--left">
                                    <span className="RouteList__routeNumber">№{item.number}</span>
                                    <Status type={item.status} />
                                </div>
                                {item.driver?.is_late && (
                                    <div className="RouteList__itemHeader--right">
                                        <Badge status="warning" title="Опаздывает" />
                                        <Typography.Text type="warning">Опаздывает</Typography.Text>
                                    </div>
                                )}
                            </div>
                            <div className="RouteList__itemDescription">
                                {item.driver !== null && (
                                    <p className="RouteList__descriptionParagraph">Водитель: {item.driver.full_name}</p>
                                )}

                                {item.actually_finished_at === null ? (
                                    <p className="RouteList__descriptionParagraph">
                                        Нужно закончить: {routesFormatter.apiDateTime(item.expected_to_finish_at)}
                                    </p>
                                ) : (
                                    <p className="RouteList__descriptionParagraph">
                                        Завершен: {routesFormatter.apiDateTime(item.actually_finished_at)}
                                    </p>
                                )}
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="RouteList__paginator">
                <Pagination
                    current={page}
                    total={total}
                    pageSize={size}
                    onChange={request}
                    showSizeChanger={false}
                    disabled={loading}
                />
            </div>
        </div>
    );
};
