import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

import { Badge, Button, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons/lib';

import { findFirst, isNonEmpty, mapWithIndex } from 'fp-ts/es6/Array';
import { elem, isNone, none, Option } from 'fp-ts/es6/Option';
import { contramap, eqNumber } from 'fp-ts/es6/Eq';
import { lookup } from 'fp-ts/es6/Record';
import { pipe } from 'fp-ts/es6/function';

import { useStore } from 'effector-react';
import { fetchRouteDetails, RouteDetailsStore } from '../../effector/routeDetails';
import { RouteDetailsData, RoutePoint } from '../../api/types';

import { RouteDescription } from '../../components/RouteDescription';
import { BlockLink } from '../../components/BlockLink';
import { Status } from '../../components/Status';
import { Loader } from '../../components/Loader';

import { showApiError } from '../../utils/notification';
import { isString } from '../../utils/guards';
import { foldView } from '../../utils/view';

import './styles.less';
import { useTimezoneFormatter } from '../../hooks/timezone';

const poorPointEq = pipe(
    eqNumber,
    contramap((point: RoutePoint) => point.id)
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapRouteDescription = (currentPoint: Option<RoutePoint>) =>
    mapWithIndex<RoutePoint, JSX.Element>((index, point) => (
        <RouteDescription
            key={point.id}
            point={point}
            index={index + 1}
            isCurrent={elem(poorPointEq)(point, currentPoint)}
            className="RouteDetails__block RouteDetails__collapse"
        />
    ));

const findFirstActivePoint = findFirst<RoutePoint>((point) => point.completion_details === null);

type URLParams = { routeId?: string; invoiceId?: string };
export const RouteDetails: FunctionComponent = () => {
    const { routeId } = useParams<URLParams>();
    const { push } = useHistory();

    const { routesFormatter } = useTimezoneFormatter();

    const detailsList = useStore(RouteDetailsStore);
    const loading = useStore(fetchRouteDetails.pending);

    const [data, setData] = useState<RouteDetailsData | null>(null);

    const onError = (err: unknown): void => {
        showApiError(err);
        setData(null);
    };

    useEffect(() => {
        if (isString(routeId)) {
            const cachedData = lookup(routeId, detailsList);
            if (isNone(cachedData)) {
                fetchRouteDetails({ id: Number(routeId) })
                    .then(setData)
                    .catch(onError);
            } else setData(cachedData.value);
        } else setData(null);
    }, [routeId, detailsList]);

    const activePoint = useMemo(() => (data ? findFirstActivePoint(data.points) : none), [data]);

    const viewState = foldView(data, loading, null);

    return (
        <section className={clsx('RouteDetails', isString(routeId) && 'RouteDetails--opened')}>
            {viewState(
                () => (
                    <div className="RouteDetails__loader">Не удалось найти маршрут</div>
                ),
                () => (
                    <div className="RouteDetails__loader">
                        <Loader />
                    </div>
                ),
                () => null,
                (details) => (
                    <>
                        <Helmet>
                            <title>Маршрут №{details.number} | Триал</title>
                        </Helmet>
                        <div className="RouteDetails__shortInfo ShortInfo">
                            <div className="ShortInfo__header">
                                <h4 className="ShortInfo__title">№{details.number}</h4>
                                <Status type={details.status} />
                            </div>
                            <div className="ShortInfo__description">
                                {details.driver && <p>Водитель: {details.driver.full_name}</p>}
                                {details.actually_finished_at === null ? (
                                    <p>Нужно закончить: {routesFormatter.apiDateTime(details.expected_to_finish_at)}</p>
                                ) : (
                                    <p>Завершен: {routesFormatter.apiDateTime(details.actually_finished_at)}</p>
                                )}
                            </div>
                            {details.driver?.is_late && (
                                <div className="ShortInfo__status">
                                    <Badge status="warning" title="Опаздывает" />
                                    <Typography.Text type="warning">Опаздывает</Typography.Text>
                                </div>
                            )}
                        </div>
                        {details.has_waybill && (
                            <BlockLink
                                to={`/routes/${routeId}/compilation`}
                                label="Общая накладная"
                                className="RouteDetails__block"
                            />
                        )}
                        {isNonEmpty(details.points) && (
                            <>
                                <div className="RouteDetails__block">
                                    <h4 className="RouteDetails__accentText">Маршрут</h4>
                                </div>
                                {mapRouteDescription(activePoint)(details.points)}
                            </>
                        )}
                        <Button
                            icon={<CloseOutlined />}
                            type="text"
                            shape="circle"
                            className="RouteDetails__close"
                            onClick={() => push('/routes')}
                        />
                    </>
                )
            )}
        </section>
    );
};
