import { createDomain } from 'effector';
import { insertAt } from 'fp-ts/es6/Record';

import { WaybillsPointDetails, WaybillsRouteDetails } from '../api/types';
import { apiPointWaybill, apiRouteWaybill } from '../api';
import { ApiError } from '../api/client/errors';
import { Protocol } from '../api/protocol';

import { clearSession } from './system';

import { eventPipe } from '../utils/effector';

const WaybillsDomain = createDomain();

export const clearWaybills = WaybillsDomain.event();
export const fetchPointWaybill = WaybillsDomain.effect<
    Protocol.WaybillsPointRequest,
    Protocol.WaybillsPointResponse,
    ApiError
>();
export const fetchRouteWaybill = WaybillsDomain.effect<
    Protocol.WaybillsRouteRequest,
    Protocol.WaybillsRouteResponse,
    ApiError
>();

fetchPointWaybill.use(apiPointWaybill);
fetchRouteWaybill.use(apiRouteWaybill);

type WaybillStorePointEntry = { kind: 'point'; value: WaybillsPointDetails };
type WaybillStoreRouteEntry = { kind: 'route'; value: WaybillsRouteDetails };

export type WaybillsStoreEntry = WaybillStoreRouteEntry | WaybillStorePointEntry;

type WaybillsState = Record<string, WaybillsStoreEntry>;
const initialState: WaybillsState = {};

const wrapPointResponse = (value: WaybillsPointDetails): WaybillsStoreEntry => ({ kind: 'point', value });
const wrapRouteResponse = (value: WaybillsRouteDetails): WaybillsStoreEntry => ({ kind: 'route', value });

type EntryType = 'point' | 'route';
export const buildCacheKey = (kind: EntryType, index: number | string): string => `${kind}-${index}`;

export const WaybillsStore = WaybillsDomain.store(initialState)
    .on(
        fetchPointWaybill.done,
        eventPipe(({ params, result }) => insertAt(buildCacheKey('point', params.point_id), wrapPointResponse(result)))
    )
    .on(
        fetchRouteWaybill.done,
        eventPipe(({ params, result }) => insertAt(buildCacheKey('route', params.route_id), wrapRouteResponse(result)))
    )
    .reset(clearWaybills, clearSession);
