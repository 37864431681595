import React, { FunctionComponent } from 'react';
import { Badge, Typography } from 'antd';
import clsx from 'clsx';

import { useTimezoneFormatter } from '../../hooks/timezone';
import { PointAction } from '../../api/types';

import './styles.less';

type RouteDescriptionListItemProps = { action: PointAction };
export const RouteDescriptionListItem: FunctionComponent<RouteDescriptionListItemProps> = ({ action }) => {
    const isLate = action.driver_is_or_was_late;
    const isFinished = action.actually_executed_at !== null;

    const { routesFormatter } = useTimezoneFormatter();

    return (
        <div className="RouteDescriptionListItem">
            <div className="RouteDescriptionListItem__header">
                <div
                    className={clsx(
                        'RouteDescriptionListItem__headerLabel',
                        isFinished && 'RouteDescriptionListItem__headerLabel--finished'
                    )}
                >
                    {action.name} в {routesFormatter.apiTime(action.execution_expected_at)}
                </div>
                {isLate && (
                    <div className="RouteDescriptionListItem__headerStatus">
                        <Badge status="warning" title="Опоздание" text={false} />
                        <Typography.Text type="warning">Опоздание</Typography.Text>
                    </div>
                )}
            </div>
            {action.actually_executed_at && (
                <div
                    className={clsx(
                        'RouteDescriptionListItem__description',
                        isLate && 'RouteDescriptionListItem__description--late'
                    )}
                >
                    Выполнено в {routesFormatter.apiTime(action.actually_executed_at)}
                </div>
            )}
        </div>
    );
};
