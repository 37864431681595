import React, { FunctionComponent, useMemo } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { Link } from 'react-router-dom';

import { Icon, LatLngTuple } from 'leaflet';

import { isEmpty, map as recordMap, reduce, reduceWithIndex } from 'fp-ts/es6/Record';
import { filter, getMonoid, isNonEmpty, mapWithIndex, snoc } from 'fp-ts/es6/Array';
import { getStructMonoid, Monoid, monoidAny } from 'fp-ts/es6/Monoid';
import { fold, groupBy, map } from 'fp-ts/es6/NonEmptyArray';
import { flow } from 'fp-ts/es6/function';

import { useStore } from 'effector-react';
import { DriverRoutesStore } from '../../effector/driverRoutes';

import { DriverLocation, NonEmptyRouteItem } from '../../api/types';
import { nonEmptyRouteGuard } from '../../utils/guards';

import DriverNormal from './icons/driver-normal.svg';
import DriverLate from './icons/driver-late.svg';

import './styles.less';

const center: LatLngTuple = [43.10562, 131.87353];

type RouteInfo = { id: number; number: string };
type DriverData = { position: LatLngTuple; routes: RouteInfo[]; late: boolean };

const getDriverLocationPoint = (location: DriverLocation): LatLngTuple => [location.lat, location.lon];

const positionMonoid: Monoid<LatLngTuple> = {
    concat: (x, y) => y,
    empty: center,
};

const driverMonoid = getStructMonoid<DriverData>({
    position: positionMonoid,
    routes: getMonoid(),
    late: monoidAny,
});

const mapRoute = map<NonEmptyRouteItem, DriverData>((item) => ({
    position: getDriverLocationPoint(item.driver.location),
    routes: [{ id: item.id, number: item.number }],
    late: item.driver.is_late,
}));

const foldBounds = reduce<DriverData, LatLngTuple[]>([], (acc, item) => snoc(acc, item.position));

const normalDriverIcon = new Icon({
    iconUrl: DriverNormal,
    iconAnchor: [17.25, 44.5],
    popupAnchor: [0, -42],
});

const lateDriverIcon = new Icon({
    iconUrl: DriverLate,
    iconAnchor: [17.25, 44.5],
    popupAnchor: [0, -42],
});

const mapMarkerRoutesListItem = mapWithIndex(
    (index, info: RouteInfo): JSX.Element => (
        <Link key={`popupLink-${index}-${info.id}`} to={`/routes/${info.id}`}>
            №{info.number}
        </Link>
    )
);

const foldMarkers = reduceWithIndex<string, DriverData, JSX.Element[]>([], (name, node, data: DriverData) =>
    snoc(
        node,
        <Marker position={data.position} key={name} icon={data.late ? lateDriverIcon : normalDriverIcon}>
            <Popup>
                <div className="DriversMap__popup">
                    <h5 className="DriversMap__popupHeading">{name}</h5>
                    {isNonEmpty(data.routes) && (
                        <ol className="DriversMap__popupList">{mapMarkerRoutesListItem(data.routes)}</ol>
                    )}
                </div>
            </Popup>
        </Marker>
    )
);

const composition = flow(
    filter(nonEmptyRouteGuard),
    groupBy((item) => item.driver.full_name),
    recordMap(flow(mapRoute, fold(driverMonoid)))
);

export const DriversMap: FunctionComponent = () => {
    const { items } = useStore(DriverRoutesStore);

    const [bounds, markers] = useMemo(() => {
        const transformed = composition(items);
        return [isEmpty(transformed) ? undefined : foldBounds(transformed), foldMarkers(transformed)];
    }, [items]);

    return (
        <Map zoom={15} bounds={bounds} center={center} className="DriversMap">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {markers}
        </Map>
    );
};
