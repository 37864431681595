import { createDomain } from 'effector';

import { Lens } from 'monocle-ts';
import { some } from 'fp-ts/es6/Record';

import { Protocol } from '../api/protocol';
import { RouteStatus, Types } from '../api/types';

import { eventPipe } from '../utils/effector';

import { clearSession } from './system';

export type NullableApiDate = Types.Date | null;
export type FiltersDatePayload = {
    from: NullableApiDate;
    to: NullableApiDate;
};

export type FiltersStatusPayload = Record<RouteStatus, boolean>;

export type FiltersBranchPayload = number | null;

type FiltersState = {
    date: FiltersDatePayload;
    status: FiltersStatusPayload;
    branch: FiltersBranchPayload;
};

const FiltersDomain = createDomain();

export const clearFilters = FiltersDomain.event();
export const setFiltersDate = FiltersDomain.event<FiltersDatePayload>();
export const setFiltersStatus = FiltersDomain.event<FiltersStatusPayload>();
export const setFiltersBranch = FiltersDomain.event<FiltersBranchPayload>();

const initialState: FiltersState = {
    branch: null,
    date: {
        from: null,
        to: null,
    },
    status: {
        new: false,
        in_progress: false,
        finished: false,
    },
};

const dateLens = Lens.fromProp<FiltersState>()('date');
const statusLens = Lens.fromProp<FiltersState>()('status');
const branchLens = Lens.fromProp<FiltersState>()('branch');

export const FiltersStore = FiltersDomain.store(initialState)
    .on(setFiltersDate, eventPipe(dateLens.set))
    .on(setFiltersStatus, eventPipe(statusLens.set))
    .on(setFiltersBranch, eventPipe(branchLens.set))
    .reset(clearSession, clearFilters);

const someFlagTrue = some((flag: boolean) => flag);

type FiltersPayload = Omit<Protocol.DriverRoutesRequest, 'page'>;
export const FiltersPayloadStore = FiltersStore.map<FiltersPayload>((state) => {
    const payload: FiltersPayload = {};

    if (state.date.from) payload.date_from = state.date.from;
    if (state.date.to) payload.date_to = state.date.to;
    if (someFlagTrue(state.status)) payload.status = state.status;
    if (state.branch !== null) payload.branch_id = state.branch;

    return payload;
});

export const $filterBranch = FiltersStore.map(({ branch }) => branch);
