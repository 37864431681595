import React, { FunctionComponent } from 'react';

import clsx from 'clsx';

import { PointCompletionDetails } from '../../api/types';

import NotDelivered from './icons/notDelivered.svg';
import Delivered from './icons/delivered.svg';
import Late from './icons/late.svg';

import './styles.less';

type RouteDescriptionStatusProps = {
    details: PointCompletionDetails | null;
    index: number;
    active: boolean;
};
export const RouteDescriptionStatus: FunctionComponent<RouteDescriptionStatusProps> = ({ details, index, active }) => {
    switch (details?.status) {
        case 'failure':
            return <img src={NotDelivered} alt="Товар не доставлен" />;
        case 'partial_success':
            return <img src={Late} alt="Товар доставлен с опозданием" />;
        case 'success':
            return <img src={Delivered} alt="Товар доставлен" />;
        default:
            return (
                <div
                    className={clsx(
                        'RouteDescriptionStatus--unknownDetails',
                        active && 'RouteDescriptionStatus--active'
                    )}
                >
                    {index}
                </div>
            );
    }
};
