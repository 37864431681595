import React, { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useKey } from 'react-use';

import { RouteListFilters } from '../../components/RouteListFilters';
import { DriversMap } from '../../components/DriversMap';
import { RouteList } from '../../components/RouteList';

import { InvoiceDetails } from '../InvoiceDetails';
import { RouteDetails } from '../RouteDetails';

import './styles.less';

type URLParams = { routeId?: string; invoiceId?: string };
export const DriverRoutes: FunctionComponent = () => {
    const { routeId, invoiceId } = useParams<URLParams>();
    const { push } = useHistory();

    useKey(
        (evt) => evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === 27,
        () => {
            if (invoiceId) push(`/routes/${routeId}`);
            else if (routeId) push('/routes');
        }
    );

    return (
        <section className="Routes">
            <aside className="Routes__routeList">
                <RouteListFilters />
                <RouteList />
            </aside>
            <main className="Routes__driversMap">
                <DriversMap />
                <RouteDetails />
                <InvoiceDetails />
            </main>
        </section>
    );
};
