import React, { FunctionComponent, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from 'rc-field-form/lib/interface';
import { Helmet } from 'react-helmet';
import { Button, Form, Input, notification, Select } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { pipe } from 'fp-ts/es6/function';
import { head } from 'fp-ts/es6/Array';
import { fold } from 'fp-ts/es6/Option';

import { useStore } from 'effector-react';
import { updateSession } from '../../effector/system';
import { BranchesStore } from '../../effector/branches';

import { Protocol } from '../../api/protocol';

import { FeaturedLayout } from '../../containers/FeaturedLayout';

import './styles.less';

export const Login: FunctionComponent = () => {
    const loading = useStore(updateSession.pending);
    const branches = useStore(BranchesStore);

    const [form] = Form.useForm();
    const firstBranch = useMemo(
        () =>
            pipe(
                branches,
                head,
                fold(
                    () => undefined,
                    (branch) => branch.id
                )
            ),
        [branches]
    );

    // Form handler
    const onFinish = (values: Store): void => {
        const payload = values as Protocol.LoginRequest;
        updateSession(payload).catch((e) => {
            notification.error({
                message: 'Ошибка',
                description: e.getCommonFirstMessage(),
            });
        });
    };

    return (
        <FeaturedLayout>
            <Helmet>
                <title>Триал | Приложение диспетчера</title>
            </Helmet>
            <Form form={form} className="LoginForm" onFinish={onFinish} initialValues={{ branch_id: firstBranch }}>
                {/* -----Title----- */}
                <h3 className="LoginForm__title">Панель диспетчера</h3>

                {/* -----Login----- */}
                <Form.Item
                    name="login"
                    className="LoginForm__inputContainer"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите логин',
                        },
                    ]}
                >
                    <Input
                        className="LoginForm__input"
                        size="large"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Логин"
                    />
                </Form.Item>

                {/* -----Password----- */}
                <Form.Item
                    name="password"
                    className="LoginForm__inputContainer"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите пароль',
                        },
                    ]}
                >
                    <Input
                        className="LoginForm__input"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        size="large"
                        placeholder="Пароль"
                    />
                </Form.Item>

                {/* -----Branch----- */}
                <Form.Item
                    name="branch_id"
                    className="LoginForm__inputContainer"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, выберите филиал',
                        },
                    ]}
                >
                    <Select size="large" placeholder="Филиал">
                        {branches.map((branch) => (
                            <Select.Option key={`branch-id-${branch.id}`} value={branch.id}>
                                {branch.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* -----Submit----- */}
                <Button htmlType="submit" className="LoginForm__submit" type="primary" size="large" loading={loading}>
                    Войти
                </Button>
            </Form>
        </FeaturedLayout>
    );
};
