import { createDomain } from 'effector';

import { isNonEmpty, head, sort } from 'fp-ts/es6/Array';
import { contramap, ordString } from 'fp-ts/es6/Ord';
import { toNullable, map } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';

import { Protocol } from '../api/protocol';
import { ApiError } from '../api/client/errors';
import { apiBranchesList } from '../api';

import { $profileAbilityFilterRouteBranch } from './profile';
import { setFiltersBranch } from './filters';
import { clearSession } from './system';

export const BranchesDomain = createDomain();

export const clearBranches = BranchesDomain.event();
export const fetchBranches = BranchesDomain.effect<void, Protocol.BranchesListResponse, ApiError>();

fetchBranches.use(apiBranchesList);

export type Branch = {
    id: number;
    name: string;
    timezone: string;
};

type BranchesState = Branch[];

const initialState: BranchesState = [];

const branchesOrd = contramap((branch: Branch) => branch.name)(ordString);

export const BranchesStore = BranchesDomain.createStore<BranchesState>(initialState)
    .on(fetchBranches.doneData, (state, { items }) => sort(branchesOrd)(items))
    .reset(clearSession, clearBranches);

export const $hasBranches = BranchesStore.map(isNonEmpty);

BranchesStore.watch($profileAbilityFilterRouteBranch, (branches, canFilterBranches) => {
    setFiltersBranch(
        canFilterBranches
            ? pipe(
                  branches,
                  head,
                  map((branch) => branch.id),
                  toNullable
              )
            : null
    );
});
