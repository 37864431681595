import React, { FunctionComponent, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { DatePicker, Select } from 'antd';

import { Moment } from 'moment';

import { Iso } from 'monocle-ts';
import { flow } from 'fp-ts/es6/function';
import { reduce, snoc } from 'fp-ts/es6/Array';
import { filter, insertAt, reduceWithIndex } from 'fp-ts/es6/Record';
import { fromNullable, mapNullable, toNullable } from 'fp-ts/es6/Option';

import { useStore } from 'effector-react';
import {
    FiltersDatePayload,
    FiltersStatusPayload,
    FiltersStore,
    NullableApiDate,
    setFiltersDate,
    setFiltersStatus,
    setFiltersBranch,
} from '../../effector/filters';
import { BranchesStore } from '../../effector/branches';
import { $profileAbilityFilterRouteBranch } from '../../effector/profile';

import { apiDateIso } from '../../utils/api';
import { KnownOptions, RouteStatus } from '../../api/types';

import './styles.less';

type NullableDate = Moment | null;
type DateState = [NullableDate, NullableDate] | null;
type DatePayload = FiltersDatePayload;

const dateIso = new Iso<NullableApiDate, NullableDate>(
    flow(fromNullable, mapNullable(apiDateIso.to), toNullable),
    flow(fromNullable, mapNullable(apiDateIso.from), toNullable)
);

const datesControlIso = new Iso<DateState, DatePayload>(
    (dates) => {
        const [from, to] = dates?.map(dateIso.from) || [null, null];
        return { from, to };
    },
    ({ from, to }) => [dateIso.to(from), dateIso.to(to)]
);

type ControlState = RouteStatus[];
type PayloadState = FiltersStatusPayload;

const statusControlIso = new Iso<ControlState, PayloadState>(
    reduce({ new: false, in_progress: false, finished: false } as PayloadState, (state, key) =>
        insertAt(key, true)(state)
    ),
    flow(
        filter((flag) => flag),
        reduceWithIndex([] as ControlState, (key: RouteStatus, payload) => [...payload, key])
    )
);

const reduceOptions = reduceWithIndex([] as JSX.Element[], (key, acc, name: string): JSX.Element[] =>
    snoc(
        acc,
        <Select.Option value={key} key={`option-${key}`}>
            {name}
        </Select.Option>
    )
);

const { RangePicker } = DatePicker;

export const RouteListFilters: FunctionComponent = () => {
    const branches = useStore(BranchesStore);
    const showBranches = useStore($profileAbilityFilterRouteBranch);
    const { date, status, branch } = useStore(FiltersStore);

    const { replace } = useHistory();

    const redirectToList = (): void => replace('/routes');

    const datesValue = useMemo(() => datesControlIso.from(date), [date]);
    const handleDatesChange = flow(datesControlIso.to, setFiltersDate, redirectToList);

    const selectValue = useMemo(() => statusControlIso.from(status), [status]);
    const handleStatusChange = flow(statusControlIso.to, setFiltersStatus, redirectToList);

    const branchValue = useMemo(() => branch || undefined, [branch]);
    const handleBranchChange = flow(setFiltersBranch, redirectToList);

    return (
        <div className={clsx('RouteListFilters', showBranches && 'RouteListFilters--withBranches')}>
            <div className="RouteListFilters__item">
                <RangePicker
                    style={{ width: '100%' }}
                    allowEmpty={[true, true]}
                    format="D MMMM YYYY"
                    value={datesValue}
                    onChange={(value) => handleDatesChange(value as DateState)}
                />
            </div>
            <div className="RouteListFilters__item">
                <Select
                    style={{ width: '100%' }}
                    placeholder="Статус"
                    mode="multiple"
                    value={selectValue}
                    onChange={handleStatusChange}
                >
                    {reduceOptions(KnownOptions)}
                </Select>
            </div>
            {showBranches && (
                <div className="RouteListFilters__item">
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Филиал"
                        value={branchValue}
                        onChange={handleBranchChange}
                    >
                        {branches.map((item) => (
                            <Select.Option key={`branch-id-${item.id}`} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            )}
        </div>
    );
};
