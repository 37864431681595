import React, { FunctionComponent, useState } from 'react';

import { Button, Modal } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import { useStore } from 'effector-react';
import { forgotSession } from '../../effector/system';
import { currentProfileFullNameStore } from '../../effector/profile';

import { DateTimeInfo } from '../DateTimeInfo';

import logoPath from '../../assets/logo.svg';
import './styles.less';

export const Header: FunctionComponent = () => {
    const fullName = useStore(currentProfileFullNameStore);
    const [isLogoutModalVisible, setLogoutModalVisibility] = useState(false);

    return (
        <header className="Header">
            <div className="Header__left">
                <img className="Header__logo" src={logoPath} alt="" />
                <DateTimeInfo />
            </div>
            <div className="Header__misc">
                <div className="Header__username">{fullName}</div>
                <Button icon={<LogoutOutlined />} onClick={() => setLogoutModalVisibility(true)} />
            </div>

            {/* Logout modal */}
            <Modal
                visible={isLogoutModalVisible}
                onOk={() => forgotSession()}
                cancelText="Отмена"
                okText="Выйти"
                onCancel={() => {
                    setLogoutModalVisibility(false);
                }}
            >
                <p>Вы действительно хотите выйти из аккаунта?</p>
            </Modal>
        </header>
    );
};
