import './App.less';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { notification } from 'antd';

import { useStore } from 'effector-react';
import { $hasBranches, fetchBranches } from './effector/branches';
import { fetchProfileDetails } from './effector/profile';
import { forgotSession, SystemStore } from './effector/system';

import { PrivateRoute } from './router/PrivateRoute';

import { FeaturedLayout } from './containers/FeaturedLayout';
import { Layout } from './containers/Layout';

import { Loader } from './components/Loader';

import { DriverRoutes } from './views/DriverRoutes';
import { Login } from './views/Login';

const RootPath = process.env.PUBLIC_URL || '/';

const App: FunctionComponent = () => {
    const hasBranches = useStore($hasBranches);
    const { loggedIn } = useStore(SystemStore);
    const logoutPending = useStore(forgotSession.pending);
    const profilePending = useStore(fetchProfileDetails.pending);
    const branchesPending = useStore(fetchBranches.pending);

    const [initialized, setInitialized] = useState(false);

    const loading = logoutPending || profilePending || branchesPending;

    useEffect(() => {
        setInitialized(true);

        const initStack: Promise<unknown>[] = [];

        if (!hasBranches) initStack.push(fetchBranches());
        if (loggedIn) initStack.push(fetchProfileDetails());

        Promise.all(initStack).catch((e) =>
            notification.error({
                message: 'Ошибка',
                description: e.getCommonFirstMessage(),
            })
        );
    }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading || !initialized)
        return (
            <FeaturedLayout>
                <Loader />
            </FeaturedLayout>
        );

    return (
        <Router basename={RootPath}>
            <Switch>
                <Route path="/" exact>
                    {loggedIn ? <Redirect to="/routes" /> : <Login />}
                </Route>

                <PrivateRoute path="/routes/:routeId?/:invoiceId?">
                    <Layout>
                        <DriverRoutes />
                    </Layout>
                </PrivateRoute>
            </Switch>
        </Router>
    );
};

const app = App;
export { app as App };
