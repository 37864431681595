import { createDomain } from 'effector';

import { insertAt } from 'fp-ts/es6/Record';

import { Protocol } from '../api/protocol';
import { ApiError } from '../api/client/errors';
import { apiRouteDetails } from '../api';

import { eventPipe } from '../utils/effector';

import { clearSession } from './system';

const RouteDetailsDomain = createDomain();

export const clearRouteDetails = RouteDetailsDomain.event();
export const fetchRouteDetails = RouteDetailsDomain.effect<
    Protocol.DriverRouteDetailsRequest,
    Protocol.DriverRouteDetailsResponse,
    ApiError
>();

fetchRouteDetails.use(apiRouteDetails);

type RouteDetailsState = Record<string, Protocol.DriverRouteDetailsResponse>;

const initialState: RouteDetailsState = {};

export const RouteDetailsStore = RouteDetailsDomain.store(initialState)
    .on(
        fetchRouteDetails.doneData,
        eventPipe((response) => insertAt(response.id.toString(), response))
    )
    .reset(clearRouteDetails, clearSession);
