import React, { FunctionComponent, ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useStore } from 'effector-react';
import { SystemStore } from '../effector/system';

type PrivateProps = {
    redirectTo?: string;
    children: ReactNode;
    exact?: boolean;
    path: string;
};

type PrivateRouteProps = PrivateProps & RouteProps;
export const PrivateRoute: FunctionComponent<PrivateRouteProps> = (props) => {
    const { path, exact = false, redirectTo = '/', children } = props;

    const { loggedIn } = useStore(SystemStore);

    return (
        <Route
            path={path}
            exact={exact}
            render={({ location }) => {
                return loggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectTo,
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};
