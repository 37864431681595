import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { FileOutlined } from '@ant-design/icons/lib';
import clsx from 'clsx';

import './styles.less';

type BlockLinkProps = { to: string; label: string; className?: string };
export const BlockLink: FunctionComponent<BlockLinkProps> = (props) => (
    <Link className={clsx(props.className, 'BlockLink')} to={props.to}>
        <FileOutlined className="BlockLink__icon" />
        <span className="BlockLink__text">{props.label}</span>
    </Link>
);
