import React, { createRef, FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { mapWithIndex } from 'fp-ts/es6/Array';

import { DownOutlined } from '@ant-design/icons/lib';

import { RouteDescriptionListItem } from '../RouteDescriptionListItem';
import { RouteDescriptionStatus } from '../RouteDescriptionStatus';
import { BlockLink } from '../BlockLink';

import { CompletionStatus, PointAction, RoutePoint } from '../../api/types';

import './styles.less';

const mapListItems = mapWithIndex<PointAction, JSX.Element>((index, action) => (
    <div className="RouteDescription__contentBlock" key={index}>
        <RouteDescriptionListItem action={action} />
    </div>
));

type URLParams = { routeId?: string };
type RouteDescriptionProps = {
    point: RoutePoint;
    className?: string;
    index: number;
    isCurrent: boolean;
};
export const RouteDescription: FunctionComponent<RouteDescriptionProps> = (props) => {
    const { className, point } = props;

    const { routeId } = useParams<URLParams>();
    const containerRef = createRef<HTMLDivElement>();
    const [open, setOpen] = useState(false);

    const colorStatusMap: Record<CompletionStatus, string> = {
        failure: 'RouteDescription__contentStatus--failure',
        partial_success: 'RouteDescription__contentStatus--partialSuccess',
        success: 'RouteDescription__contentStatus--success',
    };

    useEffect(() => {
        const { current } = containerRef;
        if (current) {
            current.style.maxHeight = open ? `${current.scrollHeight}px` : '';
        }
    }, [containerRef, open]);

    const note = point.completion_details?.action_variation?.name || '';
    const status: CompletionStatus = point.completion_details?.status ?? 'failure';

    return (
        <div className={clsx(className, 'RouteDescription')}>
            <button className="RouteDescription__header" type="button" onClick={() => setOpen(!open)}>
                <RouteDescriptionStatus
                    details={point.completion_details}
                    index={props.index}
                    active={props.isCurrent}
                />
                <h5 className="RouteDescription__headerLabel">{point.address}</h5>
                <span className="RouteDescription__buttonState">
                    <div
                        className={clsx(
                            'RouteDescription__buttonStateIcon',
                            open && 'RouteDescription__buttonStateIcon--opened'
                        )}
                    >
                        <DownOutlined />
                    </div>
                </span>
            </button>
            <div
                ref={containerRef}
                className={clsx('RouteDescription__content', open && 'RouteDescription__content--opened')}
            >
                <div className={clsx('RouteDescription__contentStatus', colorStatusMap[status])}>{note}</div>
                {point.has_waybill && (
                    <BlockLink
                        to={`/routes/${routeId}/${point.id}`}
                        label="Накладные по точке"
                        className="RouteDescription__contentBlock RouteDescription__link"
                    />
                )}
                {mapListItems(point.actions)}
            </div>
        </div>
    );
};
