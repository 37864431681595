import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import { Root } from './containers/Root';

import './index.css';

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

const dsn = process.env.REACT_APP_SENTRY_DSN;
if (dsn) {
    Sentry.init({
        dsn,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            Sentry.replayIntegration({
                stickySession: false,
                maskAllInputs: false,
                maskAllText: false,
            }),
        ],
    });
}
