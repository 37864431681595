export declare namespace Types {
    const DateTag: symbol;
    const DatetimeTag: symbol;

    export type Date = Opaque<string, typeof DateTag>;
    export type Datetime = Opaque<string, typeof DatetimeTag>;
}

export type RouteStatus = 'finished' | 'in_progress' | 'new';

export type DriverLocation = {
    lat: number;
    lon: number;
};

export type RouteDriver = {
    id: number;
    full_name: string;
    is_late: boolean;
    location: DriverLocation | null;
};

export type RouteListItem = {
    id: number;
    number: string;
    status: RouteStatus;
    expected_to_finish_at: Types.Datetime;
    actually_finished_at: Types.Datetime | null;
    driver: RouteDriver | null;
};

export type NonEmptyRouteItem = Omit<RouteListItem, 'driver'> & {
    driver: NonNullableProps<RouteDriver>;
};

export type CompletionStatus = 'failure' | 'partial_success' | 'success';
export type VariationId = 'delivered_completely' | 'delivered_partially' | 'not_delivered';

export type Variation = {
    id: VariationId;
    name: string;
    status: CompletionStatus;
};

export type PointCompletionDetails = {
    status: CompletionStatus;
    action_variation: Variation;
};

export type PointAction = {
    name: string;
    execution_expected_at: Types.Datetime;
    actually_executed_at: Types.Datetime | null;
    selected_variation: Variation;
    driver_is_or_was_late: boolean;
};

export type RoutePoint = {
    id: number;
    address: string;
    has_waybill: boolean;
    driver_was_late: boolean;
    completion_details: PointCompletionDetails | null;
    actions: PointAction[];
};

export type RouteDetailsData = {
    id: number;
    number: string;
    status: RouteStatus;
    expected_to_finish_at: Types.Datetime;
    actually_finished_at: Types.Datetime | null;
    driver: RouteDriver | null;
    has_waybill: boolean;
    points: RoutePoint[];
};

export type WaybillsEntry = {
    id: number
    name: string
    primary_quantity: number
    secondary_quantity: number | null
    secondary_quantity_remainder: number | null
    primary_measure_unit: string
    secondary_measure_unit: string | null
    primary_price_item: number
    secondary_price_item: number | null
    price_total: number
};

export type Waybill = {
    id: number;
    number: string;
    barcode: string;
    edi_number: string | null;
    total_price: number;
    collected_money_amount: number;
    entries: WaybillsEntry[];
}

export type WaybillDetails = {
    address: string;
    payment_type: 'credit' | 'fact';
    total_price: number;
    waybills: Waybill[];
};

export type WaybillsPointDetails = WaybillDetails;
export type WaybillsRouteDetails = Omit<WaybillDetails, 'address'>;

export const KnownOptions: Record<RouteStatus, string> = {
    new: 'Новый',
    in_progress: 'В работе',
    finished: 'Выполнен',
};
