import React, { FunctionComponent } from 'react';
import { Tag } from 'antd';
import { KnownOptions, RouteStatus } from '../api/types';

type StatusProps = { type: RouteStatus };
export const Status: FunctionComponent<StatusProps> = (props) => {
    switch (props.type) {
        case 'new':
            return <Tag color="blue">{KnownOptions.new}</Tag>;
        case 'in_progress':
            return <Tag color="green">{KnownOptions.in_progress}</Tag>;
        case 'finished':
            return <Tag color="default">{KnownOptions.finished}</Tag>;
        default:
            return null;
    }
};
