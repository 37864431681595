import { NonEmptyRouteItem, RouteListItem } from '../api/types';

export const isArray = (value: unknown): value is Array<unknown> => Array.isArray(value);
export const isObject = (value: unknown): value is Record<string, unknown> =>
    value !== null && typeof value === 'object';
export const isString = (value: unknown): value is string => typeof value === 'string';
export const isUndefined = (value: unknown): value is undefined => value === undefined;
export const possibleToBeNumber = (value: unknown): value is number =>
    isString(value) && /^[-+]?[0-9]*\.?[0-9]+$/.test(value);
export const nonEmptyRouteGuard = (item: RouteListItem): item is NonEmptyRouteItem => {
    return !!item?.driver?.location;
};
