import React, { createRef, FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { List } from 'antd';

import { DownOutlined } from '@ant-design/icons/lib';

import { formatPrice } from '../../utils/view';

import { Waybill, WaybillsEntry } from '../../api/types';

import './styles.less';

const foldWaybillsEntryUnit = (entry: WaybillsEntry): string => {
    const unitChunks = [`${entry.primary_quantity} ${entry.primary_measure_unit}`];

    if (entry.secondary_quantity_remainder && entry.secondary_measure_unit) {
        unitChunks.push(`${entry.secondary_quantity_remainder} ${entry.secondary_measure_unit}`);
    }

    return unitChunks.join(', ');
};

type RouteInvoiceProps = {
    waybill: Waybill;
    className?: string;
};
export const RouteInvoice: FunctionComponent<RouteInvoiceProps> = (props) => {
    const { waybill, className } = props;

    const containerRef = createRef<HTMLDivElement>();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const { current } = containerRef;
        if (current) {
            current.style.maxHeight = isOpen ? `${current.scrollHeight}px` : '';
        }
    }, [containerRef, isOpen]);

    return (
        <div className={clsx(className, 'RouteInvoice')}>
            <button className="RouteInvoice__header" type="button" onClick={() => setIsOpen(!isOpen)}>
                <div className="RouteInvoice__headerInfo">
                    <h5 className="RouteInvoice__headerLabel">№{waybill.number}</h5>
                    <h5 className="RouteInvoice__headerLabel">{formatPrice(waybill.total_price)}</h5>
                    {waybill.edi_number !== null && (
                        <h5 className="RouteInvoice__headerLabel">EDI: {waybill.edi_number}</h5>
                    )}
                </div>
                <span className="RouteInvoice__buttonState">
                    <div
                        className={clsx(
                            'RouteInvoice__buttonStateIcon',
                            isOpen && 'RouteInvoice__buttonStateIcon--isOpen'
                        )}
                    >
                        <DownOutlined />
                    </div>
                </span>
            </button>
            <div
                ref={containerRef}
                className={clsx('RouteInvoice__content', isOpen && 'RouteInvoice__content--isOpen')}
            >
                <List
                    className="RouteInvoice__list"
                    dataSource={waybill.entries}
                    renderItem={(item) => (
                        <List.Item className="RouteInvoice__listItem">
                            <div className="RouteInvoice__itemLabel">
                                {item.name} — {foldWaybillsEntryUnit(item)}
                            </div>
                            <div className="RouteInvoice__itemAmount">{formatPrice(item.price_total)}</div>
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};
