import { Iso } from 'monocle-ts';
import moment, { Moment } from 'moment';

import { Types } from '../api/types';

const ApiDateFormat = 'YYYY.MM.DD';
export const apiDateIso = new Iso<Types.Date, Moment>(
    (apiDate) => moment(apiDate, ApiDateFormat),
    (instance) => instance.format(ApiDateFormat) as Types.Date
);

export const apiDatetimeIso = new Iso<Types.Datetime, Moment>(
    (apiDate) => moment(apiDate),
    (instance) => instance.format() as Types.Datetime
);
