import { createDomain } from 'effector';

import { Protocol } from '../api/protocol';
import { ApiError } from '../api/client/errors';
import { apiProfileDetails } from '../api';

export type ProfileBranch = {
    id: number;
    name: string;
    timezone: string;
};

export type ProfileAction = 'filter_route_branch';

export type ProfileState = {
    id: number;
    login: string;
    full_name: string;
    push_token: string | null;
    branch: ProfileBranch;
    _actions: ProfileAction[];
};

const initialState: ProfileState = {
    id: 0,
    login: '',
    full_name: '',
    push_token: null,
    branch: { id: -1, name: '', timezone: 'Asia/Vladivostok' },
    _actions: [],
};

export const ProfileDomain = createDomain();

export const clearSession = ProfileDomain.event();
export const fetchProfileDetails = ProfileDomain.effect<
    Protocol.ProfileDetailsRequest,
    Protocol.ProfileDetailsResponse,
    ApiError
>();

fetchProfileDetails.use(apiProfileDetails);

export const ProfileStore = ProfileDomain.store<ProfileState>(initialState)
    .on(fetchProfileDetails.doneData, (state, payload) => payload)
    .reset(clearSession);

export const currentProfileFullNameStore = ProfileStore.map((s) => s.full_name);

export const $profileTimezone = ProfileStore.map(({ branch: { timezone } }) => timezone);

// eslint-disable-next-line no-underscore-dangle
export const $profileAbilityFilterRouteBranch = ProfileStore.map((s) => s._actions.includes('filter_route_branch'));
