import { createDomain, forward } from 'effector';
import withStorage from 'effector-storage';

import { ApiClient, apiLogin, apiLogout } from '../api';
import { ApiError } from '../api/client/errors';
import { Protocol } from '../api/protocol';

export const SystemDomain = createDomain();
const createStorageStore = withStorage(SystemDomain.store);

export const clearSession = SystemDomain.event();
export const updateSession = SystemDomain.effect<Protocol.LoginRequest, Protocol.LoginResponse, ApiError>();
export const forgotSession = SystemDomain.effect<Protocol.LogoutRequest, Protocol.LogoutResponse, ApiError>();

updateSession.use(apiLogin);
forgotSession.use(apiLogout);

export type SystemState = {
    loggedIn: boolean;
};

const initialState: SystemState = {
    loggedIn: false,
};

const cfg = { key: 'system' };
export const SystemStore = createStorageStore<SystemState>(initialState, cfg)
    .on(updateSession.done, () => ({ loggedIn: true }))
    .reset(clearSession, forgotSession.done);

ApiClient.setInvalidTokenHandler(() => clearSession());

forward({ from: forgotSession.done, to: clearSession });
