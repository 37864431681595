import { combine, createDomain, forward } from 'effector';

import { findFirst } from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/function';
import { fold } from 'fp-ts/es6/Option';

import { Protocol } from '../api/protocol';
import { ApiError } from '../api/client/errors';
import { apiDriverRoutes } from '../api';

import { $profileTimezone } from './profile';
import { BranchesStore } from './branches';
import { clearSession } from './system';
import { $filterBranch, setFiltersBranch } from './filters';

export const DriverRoutesDomain = createDomain();

export const clearDriverRoutes = DriverRoutesDomain.event();
export const fetchDriverRoutes = DriverRoutesDomain.effect<
    Protocol.DriverRoutesRequest,
    Protocol.DriverRoutesResponse,
    ApiError
>();

fetchDriverRoutes.use(apiDriverRoutes);

type DriverRoutesState = Protocol.DriverRoutesResponse;

const initialState: DriverRoutesState = {
    items: [],
    page: 1,
    total: 0,
    page_size: 0,
};

export const DriverRoutesStore = DriverRoutesDomain.createStore<DriverRoutesState>(initialState)
    .on(fetchDriverRoutes.doneData, (state, payload) => payload)
    .reset(clearSession, clearDriverRoutes);

forward({ from: setFiltersBranch, to: clearDriverRoutes });

export const $routesTimezone = combine(
    BranchesStore,
    $filterBranch,
    $profileTimezone,
    (branches, currentBranch, defaultTz) =>
        pipe(
            branches,
            findFirst((branch) => branch.id === currentBranch),
            fold(
                () => defaultTz,
                (branch) => branch.timezone
            )
        )
);
