import { useStore } from 'effector-react';
import { flow } from 'fp-ts/es6/function';

import { Moment } from 'moment';

import { $routesTimezone } from '../effector/driverRoutes';
import { $profileTimezone } from '../effector/profile';

import { apiDateIso, apiDatetimeIso } from '../utils/api';
import { formatDate, formatDatetime, formatTime, withTimezone } from '../utils/view';

import { Types } from '../api/types';

type SectionType = 'routesFormatter' | 'profileFormatter';

type ApiFormatters = {
    apiDate: (a: Types.Date) => string;
    apiTime: (a: Types.Datetime) => string;
    apiDateTime: (a: Types.Datetime) => string;
};

type MomentFormatters = {
    date: (a: Moment) => string;
    time: (a: Moment) => string;
    dateTime: (a: Moment) => string;
};

export type SectionFormatter = ApiFormatters & MomentFormatters;
export type UseTimezoneFormatterContract = Record<SectionType, SectionFormatter>;

export function useTimezoneFormatter(): UseTimezoneFormatterContract {
    const routesTimezone = useStore($routesTimezone);
    const profileTimezone = useStore($profileTimezone);

    const sectionBuilder = (tz: string): SectionFormatter => {
        const date = flow(withTimezone(tz), formatDate);
        const time = flow(withTimezone(tz), formatTime);
        const dateTime = flow(withTimezone(tz), formatDatetime);

        return {
            date,
            time,
            dateTime,
            apiDate: flow(apiDateIso.to, date),
            apiTime: flow(apiDatetimeIso.to, time),
            apiDateTime: flow(apiDatetimeIso.to, dateTime),
        };
    };

    return {
        routesFormatter: sectionBuilder(routesTimezone),
        profileFormatter: sectionBuilder(profileTimezone),
    };
}
