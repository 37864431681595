import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';

import { lookup } from 'fp-ts/es6/Record';
import { isNone } from 'fp-ts/es6/Option';

import { CloseOutlined } from '@ant-design/icons/lib';
import { Button } from 'antd';

import { useStore } from 'effector-react';
import { RouteDetailsStore } from '../../effector/routeDetails';
import {
    buildCacheKey,
    fetchPointWaybill,
    fetchRouteWaybill,
    WaybillsStore,
    WaybillsStoreEntry,
} from '../../effector/waybills';

import { showApiError } from '../../utils/notification';
import { isString } from '../../utils/guards';
import { foldView } from '../../utils/view';

import { Loader } from '../../components/Loader';
import { RouteInvoice } from '../../components/RouteInvoice';

import './styles.less';

type URLParams = { routeId?: string; invoiceId?: 'compilation' | string };
export const InvoiceDetails: FunctionComponent = () => {
    const { routeId, invoiceId } = useParams<URLParams>();
    const { push } = useHistory();

    const routeLoading = useStore(fetchRouteWaybill.pending);
    const pointLoading = useStore(fetchPointWaybill.pending);
    const loading = routeLoading || pointLoading;

    const cacheList = useStore(WaybillsStore);
    const detailsList = useStore(RouteDetailsStore);
    const [data, setData] = useState<WaybillsStoreEntry | null>(null);

    const isRoute = invoiceId === 'compilation';
    const routeNumber = detailsList[Number(routeId)]?.number ?? '';

    useEffect(() => {
        if (isString(routeId) && isString(invoiceId)) {
            const cacheKey = isRoute ? buildCacheKey('route', routeId) : buildCacheKey('point', invoiceId);

            const cacheData = lookup(cacheKey, cacheList);
            if (isNone(cacheData)) {
                const task = isRoute
                    ? fetchRouteWaybill({ route_id: Number(routeId) })
                    : fetchPointWaybill({ point_id: Number(invoiceId) });
                task.catch(showApiError);
            } else setData(cacheData.value);
        } else setData(null);
    }, [routeId, invoiceId, cacheList, isRoute]);

    const viewState = foldView(data, loading, null);

    return (
        <section className={clsx('InvoiceDetails', isString(invoiceId) && 'InvoiceDetails--opened')}>
            {viewState(
                () => (
                    <div className="InvoiceDetails__center">
                        {isRoute ? 'Не удалось найти маршрут' : 'Не удалось найти точку на маршруте'}
                    </div>
                ),
                () => (
                    <div className="InvoiceDetails__center">
                        <Loader />
                    </div>
                ),
                () => null,
                (result) => (
                    <>
                        <Helmet>
                            {result.kind === 'route' ? (
                                <title>Общая накладная по маршруту №{routeNumber} | Триал</title>
                            ) : (
                                <title>
                                    Накладная по точке {result.value.address} в маршруте №{routeNumber} | Триал
                                </title>
                            )}
                        </Helmet>
                        <h5 className="InvoiceDetails__header">
                            {result.kind === 'route' ? 'Общая накладная' : `Накладные: ${result.value.address}`}
                        </h5>
                        <ul className="InvoiceDetails__list">
                            {result.value.waybills.map((waybill) => (
                                <li className="InvoiceDetails__listItem">
                                    <RouteInvoice waybill={waybill} />
                                </li>
                            ))}
                        </ul>
                    </>
                )
            )}
            <Button
                icon={<CloseOutlined />}
                type="text"
                shape="circle"
                className="RouteDetails__close"
                onClick={() => push(`/routes/${routeId}`)}
            />
        </section>
    );
};
