import { CookieAuth } from './client/auth';
import { ApiClientClass } from './client';
import { Protocol } from './protocol';

export const ApiClient = new ApiClientClass(CookieAuth());

// ----- Methods -----
/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const apiLogin = (payload: Protocol.LoginRequest) =>
    ApiClient.post<Protocol.LoginResponse, Protocol.LoginRequest>('/api/auth/dispatcher/login', payload);

export const apiLogout = () => ApiClient.post<Protocol.LogoutResponse, null>('/api/auth/dispatcher/logout', null);

export const apiProfileDetails = () =>
    ApiClient.get<Protocol.ProfileDetailsResponse, null>('/api/user/profile/details', null);

export const apiDriverRoutes = (payload: Protocol.DriverRoutesRequest) =>
    ApiClient.get<Protocol.DriverRoutesResponse>('/api/dispatcher/routes/list', payload);

export const apiRouteDetails = (payload: Protocol.DriverRouteDetailsRequest) =>
    ApiClient.get<Protocol.DriverRouteDetailsResponse>('/api/dispatcher/routes/details', payload);

export const apiPointWaybill = (payload: Protocol.WaybillsPointRequest) =>
    ApiClient.get<Protocol.WaybillsPointResponse>('/api/dispatcher/waybills/point/view', payload);

export const apiRouteWaybill = (payload: Protocol.WaybillsRouteRequest) =>
    ApiClient.get<Protocol.WaybillsRouteResponse>('/api/dispatcher/waybills/route/view', payload);

export const apiBranchesList = () => ApiClient.get<Protocol.BranchesListResponse>('/api/branches/list', null);

/* eslint-enable @typescript-eslint/explicit-function-return-type */
